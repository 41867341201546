import { AuthService } from './auth.service';
import { User } from 'oidc-client';
import { Injectable } from '@angular/core';
import { defer, isObservable, Observable, of, throwError } from 'rxjs';
import { DcsBaseHttpService } from './dcs-base-http.service';
import { catchError, first, map, mergeMap, shareReplay, switchMap } from 'rxjs/operators';
import { UserVehicle } from '../models/service-appointments/myvehicle';
import { SpecialServiceCampaigns, VehicleHealthReport } from '../models/dashboard/vehicle-health-report';
import { ServiceHistory } from '../models/dashboard/service-history';
import { countryCd, environment,dealerCd} from './util.service';
import { SeriesData } from '../models/service-appointments/series-data';
import { IToyotaLexusModelDetails } from '../../store/service-appointment/service-appointment.reducer';
import {DealerState} from '../../store/dealer/dealer.reducer';
import { Store } from '@ngrx/store';
import { selectDealerCode } from '../../store/dealer/dealer.selectors';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserVehicleService {

  dcsb2oApi: string;
  dcsb2cApi: string;
  appointmentApi:string;
  userInfo: User;
  makeModelYear :IToyotaLexusModelDetails ;
  vinList: string[] = [];
  healthReportByVin: {vin:string,data:any}[] = [];
  private readonly aspB2cGuestApi: string;

  constructor(private readonly dcsBaseHttpService: DcsBaseHttpService,
    private readonly authService: AuthService, private readonly dealerState: Store<DealerState>,  private readonly http: HttpClient) {
    this.dcsb2oApi = environment.dcsb2oApi;
    this.dcsb2cApi = environment.dcsb2cApi;
    this.appointmentApi = environment.aspAppointmentApi;
    this.aspB2cGuestApi = environment.aspB2cGuestApi;
    setTimeout(() => {
      this.authService.user.subscribe(user => {
        this.userInfo = user;
      });
    }, 500);
  }

  getVehicle(): Observable<UserVehicle> {
    return this.dealerState.select(selectDealerCode).pipe(switchMap(code => {
    const url:string = `${this.appointmentApi}api/consumer/${code}/vehicles`
    return this.dcsBaseHttpService.get(url).pipe(
      map(
        (data: any) => {
          return {
            ...data,
              status: { code: 200 },
              vehicles: data.data
          };
        }
      ),
      catchError(err => {
        return of({});
      })
    );
    })) 
  }

  getVehicleServiceHistory(vehicleId: string): Observable<ServiceHistory> {
    const url: string = this.dcsb2cApi + (environment.useEOSForDCS ? `v1/vehicle/${vehicleId}/service-records?serviceType=nsh` : `c/profile/my-vehicle/${vehicleId}/service-record`);
    return this.dcsBaseHttpService.get(url, '1.1').pipe(
      map(
        (data: any) => {
          if (environment.useEOSForDCS) {
            return {
              status: { code: '200' },
              requestId: data?.meta?.requestId,
              serviceHistoryRecords: data?.data?.repairOrders
            };
          }
          return data;
        }
      )
    );
  }

  getSpecialServiceCampaign(vin: string): Observable<SpecialServiceCampaigns>{
    if ( !vin) {
      return throwError('Vin is required');
    }
    const url: string = this.dcsb2oApi + `v2/vehicle/safety-campaigns`;
    return this.dcsBaseHttpService.post(url, {vin}).pipe(map(
      (data: any) => {
        return {
          status: {code: '200', messages: []},
          requestId: data?.body?.meta?.requestId,
          vehicleCampaignList: data?.body?.data?.vehicleCampaignList
        }
      }
    ),   
    catchError(err => {
      return of({
        status: { code: err?.error?.code || 403, messages: [] },
        requestId: err?.meta?.requestId || 'ERR',
        vehicleCampaignList : null
      });
    })
    )
  }

  getVehicleHealthReport(userCd: string, vehicleId: string, vin: string): Observable<VehicleHealthReport> {
    if (!vehicleId || !vin) {
      return throwError('Vin and VehicleId is required');
    }
    if(this.vinList.indexOf(vin) !== -1 && this.healthReportByVin[this.vinList.indexOf(vin)].vin === vin){
      return new Observable((observer) => {
        observer.next(this.healthReportByVin[this.vinList.indexOf(vin)].data);
        observer.complete();
      });
    }else{
    const url: string = this.dcsb2cApi + (environment.useEOSForDCS ? `v1/vehicle/${vin}/vehicle-health-report` : `c/profile/my-vehicle/${vehicleId}/vehicle-health-report?vin=${vin}&userCd=${userCd}`);
    return this.dcsBaseHttpService.get(url).pipe(
      map(
        (data: any) => {
          this.vinList.push(vin);
          if (environment.useEOSForDCS) {
            this.healthReportByVin.push({vin,data:{
              status: { code: '200' },
              requestId: data.meta.requestId,
              vhr: data.data
            }})
            return {
              status: { code: '200' },
              requestId: data.meta.requestId,
              vhr: data.data
            }
          }
          this.healthReportByVin.push({vin,data})
          return data;
        }
      ),
      catchError(err => {
        return of({
          status: { code: err?.error?.code || 403 },
          requestId: err?.meta?.requestId || 'ERR',
          vhr: null
        });
      })
    );
    }
  }

  getSeriesData(): Observable<IToyotaLexusModelDetails> {
    if(this.makeModelYear !== undefined){
      return new Observable((observer) => {
          observer.next(this.makeModelYear);
          observer.complete();
        });
    }
    else{
      let url: string = `${this.aspB2cGuestApi}/catalog/${dealerCd}/${countryCd}/makemodelyear`;
      if(countryCd.includes('US')) {
        url =  `${(this.aspB2cGuestApi)}catalog/${dealerCd}/us/makemodelyear`;
      }
      const httpToyotaUrl = this.dcsBaseHttpService.get(url, '1.0', { xBrand: 'T', xClient: 'TO-WEB' });
      const httpLexusUrl = this.dcsBaseHttpService.get(url, '1.0', { xBrand: 'L', xClient: 'TO-WEB' });
      
      return httpToyotaUrl.pipe(
        switchMap((toyotaData: SeriesData) => {
          return httpLexusUrl.pipe(
            map((lexusData: SeriesData) => {
              if (environment.useEOSForDCS) {
                this.makeModelYear = {
                  toyota: toyotaData.data.vehicleModelYearList,
                  lexus: lexusData.data.vehicleModelYearList
                };
                return {
                  toyota: toyotaData.data.vehicleModelYearList,
                  lexus: lexusData.data.vehicleModelYearList
                };
              }
              this.makeModelYear = {
                toyota: toyotaData.vehicleModelYearList,
                lexus: lexusData.vehicleModelYearList
              };
              return {
                toyota: toyotaData.vehicleModelYearList,
                lexus: lexusData.vehicleModelYearList
              };
            }));
        }
        )
      );
    }
  }

  getCustomerEmailId() {
    if (this.userInfo) {
      return this.userInfo?.profile?.email;
    }
    return undefined;
  }

}

let returnObs$: Observable<any>;

const createReturnObs = (obs: Observable<any>, time: number, bufferReplays: number) =>
  (returnObs$ = obs.pipe(shareReplay(bufferReplays, time)));

export function renewAfterTimer(obs: Observable<any>, time: number, bufferReplays: number = 5) {
  return createReturnObs(obs, time, bufferReplays).pipe(
    first(null, defer(() => createReturnObs(obs, time, bufferReplays))),
    mergeMap(d => (isObservable(d) ? d : of(d))),
  );
}
