<div class='own-vehicle-loader' *ngIf="(isUserVehiclesLoading$ | async) || (modelYearAndModelsLoading$ | async)">
  <app-loading size=".5"></app-loading>
</div>
<form [formGroup]="step"
      *ngIf="!(isUserVehiclesLoading$ | async) && !(modelYearAndModelsLoading$ | async)"
      autocomplete="off"
      appConnectStore="vehicleForm">
  <div id="select-vehicle-container">
    <div class="accordion">
      <div class="accordion-header">
        <div class="title">
          <div class="step-title anime-element anime-prime"> {{ title | translate}}</div>
          <div *ngIf='!signedIn && isLoginVisible' class='login'>{{ 'appointmentInformation.alreadyACustomer' | translate}}
            <span (click)='login()'>{{ 'welcome.logIn' | translate}}</span>
        </div>
        </div>

        <div
          *ngIf="signedIn && !(isEditMode$| async)"
          id="vehicle-type-switcher"
          (click)="toggleVehicleType()">
          {{ switcherText | translate }}
          <span class="arrow"></span>
        </div>
      </div>
      <div class="accordion-panel">
        <ng-container *ngIf = 'isEditMode$ | async'>
          <div class="edit-mode-vehicle">
            <mat-icon class="mat-icon">info_outline</mat-icon>
            <span>{{ 'welcome.vehicleDetailsCanNotBeModifiedExceptMileage' | translate}}</span>
          </div>
        </ng-container>
        <!-- Own vehicle input form -->
        <ng-container *ngIf="isOwnVehicle && signedIn">
          <div class="own-vehicle-error"
                 *ngIf="!step.value.mileage">
                 {{step.errors?.required | translate}}
            </div>
          <div id="own-vehicles-container">
            <ng-container *ngIf="vehicles.length; else noVehicleFound">
              <div class="own-vehicle" *ngFor="let vehicle of vehicles">
                <label *ngIf="showMultipleVehicle ? showMultipleVehicle : vehicleId?.length && vehicle.vin === vehicleId">
                  <input type="radio"
                         [value]="vehicle.vin"
                         [checked]="step.value?.vin === vehicle.vin"
                         (change)="vehicleSelected(vehicle, vehicleCardComponent)"
                         formControlName="vin"/>
                  <div class="vehicle-card-container anime-element">
                    <app-vehicle-card #vehicleCardComponent
                      [vehicle]="vehicle"
                      [newvehicle]="newvehicle"
                      (fieldChange)="onVehicleFieldChange($event, vehicle)"
                      (toShowFooterDetails)="toShowFooterDetails($event)"
                      [editmode]="(isEditMode$ | async)"
                    ></app-vehicle-card>
                  </div>
                </label>
              </div>
            </ng-container>
            <ng-template #noVehicleFound>
              <div class="vehicle-not-found">
                <mat-icon class="icon">info</mat-icon>
                <div class="text">{{ 'welcome.vehicleNotFound' | translate }}</div>
                <div class="text-sec">
                  {{ 'welcome.thereAreNoVehiclesAssociatedWithTheCustomer.' | translate}}
                  {{ 'welcome.pleaseChooseVehicleDetailsFromChooseAnotherVehicleSectionAbove' | translate}}
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>

        <!-- Different vehicle input form -->
        <ng-container *ngIf="(!isOwnVehicle || !signedIn)">
          <div id="different-vehicle-container">
            <div class="make-year-model-container">
              <ng-container *ngIf="isEditMode$ | async">
               
                <!-- Input: make -->
                    <mat-form-field class="mat-input-container anime-element" appearance="outline">
                      <mat-label>{{ 'welcome.make' | translate }}</mat-label>
                      <input matInput placeholder="{{ 'welcome.selectAMake' | translate }}" formControlName="make" 
                      [value]="step.controls.make" />
                    </mat-form-field>
            
                <!-- based on make show  text input -->
                <ng-container *ngIf="!isMakeOther; else textInputForOther">
                  <!-- Input: Year -->
                  <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                    <mat-label>{{ 'welcome.modelYear' | translate | titlecase }}</mat-label>
                    <input matInput placeholder="{{ 'welcome.selectAYear' | translate }}" formControlName="year"
                      [value]="step.controls.year" />
                  </mat-form-field>

            
                  <!-- Input: Model -->
                  <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                    <mat-label>{{ 'welcome.model' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'welcome.selectAModel' | translate }}" formControlName="model"
                      [value]="step.controls.model" />
                    </mat-form-field>
                </ng-container>
              
            
            </ng-container>
            <ng-container *ngIf="!(isEditMode$ | async)">

           
              <!-- Input: make -->
              <mat-form-field class="mat-input-container anime-element" appearance="outline">
                <mat-label>{{ 'welcome.make' | translate }}</mat-label>
                <mat-select formControlName="make" (selectionChange)="onSelect('make',$event)">
                  <mat-option *ngFor="let make of makes" [value]="make.name.toLowerCase()">
                    {{ make.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="step.controls.make.errors?.required">{{ 'welcome.makeIsRequired' | translate }}</mat-error>
              </mat-form-field>

              <!-- based on make show drop down or text input -->
              <ng-container *ngIf="!isMakeOther; else textInputForOther">
                <!-- Input: Year -->
                <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">

                <!-- Input: Model -->

                  <mat-label>{{ 'welcome.modelYear' | translate | titlecase }}</mat-label>
                  <mat-select formControlName="year" (selectionChange)="onSelect('year',$event)">
                    <mat-option *ngFor="let s of yearmodels" [value]="s">
                      {{ s }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="step.controls.year.errors?.required">{{ 'welcome.yearIsRequired' | translate }}</mat-error>
                </mat-form-field>

                <!-- Input: Model -->
                <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                  <mat-label>{{ 'welcome.model' | translate }}</mat-label>
                  <mat-select formControlName="model" (selectionChange)="onSelect('model',$event)">
                    <mat-option *ngFor="let model of models" [value]="model" [innerHTML]="model">
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="step.controls.model.errors?.required">{{ 'welcome.modelIsRequired' | translate }}</mat-error>
                </mat-form-field>
              </ng-container>
            </ng-container>

              <!-- Text input for other makes -->
              <ng-template #textInputForOther>
                <!-- Input: Make -->
                <mat-form-field
                  class="mat-input-container anime-element anime-sec"
                  appearance="outline">
                  <mat-label>{{ 'welcome.makeName' | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'welcome.enterTheMakeName' | translate }}"
                    formControlName="otherMake"
                    (blur) = "inputBlurEvent('make')"
                  />
                  <mat-error *ngIf="step.controls.otherMake.errors?.required">{{ 'welcome.makeNameIsRequired' | translate }}</mat-error>
                </mat-form-field>
                <!-- Input: Year -->
                <mat-form-field
                  class="mat-input-container anime-element anime-sec"
                  appearance="outline">
                  <mat-label>{{ 'welcome.modelYear' | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'welcome.enterModelYear' | translate }}"
                    formControlName="year"
                    aspNumberOnly
                    (blur) = "inputBlurEvent('year')"
                  />
                  <mat-error *ngIf="step.controls.year.errors?.required">{{ 'welcome.yearIsRequired' | translate }}</mat-error>
                </mat-form-field>

                <!-- Input: Model -->
                <mat-form-field
                  class="mat-input-container anime-element anime-sec"
                  appearance="outline"
                >
                  <mat-label>{{ 'welcome.model' | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'welcome.enterModel' | translate }}"
                    formControlName="model"
                    (blur) = "inputBlurEvent('model')"
                  />
                  <mat-error *ngIf="step.controls.model.errors?.required">{{ 'welcome.modelIsRequired' | translate }}</mat-error>
                </mat-form-field>
              </ng-template>
            </div>
            <!-- make-year-model-container -->

            <div id="or" class="anime-element">{{ 'welcome.or' | translate }}</div>

            <!-- Input: VIN -->
            <div class="vin-container">
              <mat-form-field class="mat-input-container anime-element" appearance="outline">
                <mat-label>{{ 'welcome.enterYourVin' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'welcome.enter17digitVIN' | translate }}"
                  formControlName="vin"
                  [required]="isVinMandate"
                  (blur) = "inputBlurEvent('vin')"
                />
                <mat-error *ngIf="step.controls.vin.errors?.required">{{ 'welcome.vinIsRequired'| translate }}</mat-error>
                <mat-error
                  *ngIf=" step.controls.vin.errors?.pattern17DigitAndAlphaNumeric"
                >{{step.controls.vin.errors?.pattern17DigitAndAlphaNumeric | translate}}</mat-error>
                <mat-error
                  *ngIf=" step.controls.vin.errors?.pattern17Digit"
                >{{step.controls.vin.errors?.pattern17Digit | translate}}</mat-error>
                <mat-error
                  *ngIf=" step.controls.vin.errors?.patternAlphaNumeric"
                >{{step.controls.vin.errors?.patternAlphaNumeric | translate}}</mat-error>
              </mat-form-field>
              <div *ngIf="hasExistingAppt" style="font-size: 14px;color: red;padding: 0 1em;">
                <sup>{{existingApptText}}</sup>
              </div>
            </div>

            <!-- Input: Mileage -->
            <div class="anime-element">
              <div class="mileage-input-title">{{ 'welcome.enterYourMileage' | translate }}</div>
              <div class="mileage-input-hint">
                {{ 'welcome.provideAppropriateMileage' | translate }}
              </div>
            </div>
            <mat-form-field class="mat-input-container anime-element" appearance="outline">
              <mat-label>{{ estimatedMiles }}</mat-label>
              <input
                matInput
                placeholder="{{ enterMiles }}"
                formControlName="mileage"
                aspNumberOnly
                required
                (input)="onKeyPress($event)"
                (blur) = "inputBlurEvent('mileage')"
              />
              <mat-error *ngIf="step.controls.mileage.errors?.required">{{ milesIsRequired }}</mat-error>
            </mat-form-field>
          </div>
          <!-- different-vehicle-container -->

        </ng-container>
      </div>
      <!-- accordion panel -->
    </div>
  </div>
</form>
