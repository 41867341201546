<app-asp-header *ngIf="!inDealerSite"></app-asp-header>
<div class="row">
  <div class="col-md-6" style="padding: 10px 10% 2% 10%;">
    <div>
      <div style="font-size:15px;">
        <span>{{ 'welcome.continueAsGuest' | translate }}</span>
      </div>

      <div class='tab-headers'>
        <span *ngIf="!inDealerSite" class='tab-header' [ngClass]="viewMode == 'tab1' ? 'active':''" (click)="changeView('tab1')">
          {{ 'welcome.scheduleAppointment' | translate }}</span>
        <span *ngIf="logedIn" class='tab-header' style="padding-left: 20px;" [ngClass]="viewMode == 'tab2' ? 'active':''"
          (click)="changeView('tab2')">{{ 'welcome.manageAppointment' | translate }}</span>
      </div>

      <div [ngClass]="viewMode == 'tab2' && !inDealerSite ? 'active':''" class="tab-slider--tabs"></div>
      <ng-container #tab1 *ngIf="viewMode == 'tab1' && !inDealerSite">
        <div class='tab-container'>
          <form [formGroup]="step">
            <span class="details-title">{{ 'welcome.enterYourVehicleDetails' | translate }}</span>
            <div id="different-vehicle-container">
              <div class="make-year-model-container">
                <!-- Input: make -->
                <mat-form-field class="mat-input-container anime-element" appearance="outline">
                  <mat-label>{{ 'welcome.make' | translate }}</mat-label>
                  <mat-select formControlName="make">
                    <mat-option *ngFor="let make of makes" [value]="make.name">
                      {{ make.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="step.controls.make.errors?.required">{{ 'welcome.makeIsRequired' | translate }}</mat-error>
                </mat-form-field>

                <!-- based on make show drop down or text input -->
                <ng-container *ngIf="!isMakeOther; else textInputForOther">
                  <!-- Input: Year -->
                  
                  <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                    <mat-label>{{ 'welcome.model-Year' | translate }}</mat-label>
                    <mat-select formControlName="year">
                      <mat-option *ngFor="let s of yearmodels" [value]="s">
                        {{ s }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="step.controls.year.errors?.required">{{ 'welcome.yearIsRequired' | translate }}</mat-error>
                  </mat-form-field>
                  <!-- Input: Model -->
                  <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                    <mat-label>{{ 'welcome.model' | translate }}</mat-label>
                    <mat-select formControlName="model">
                      <mat-option *ngFor="let model of models" [innerHTML]="model" [value]="model">
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="step.controls.model.errors?.required">{{ 'welcome.modelIsRequired' | translate }}</mat-error>
                  </mat-form-field>
                </ng-container>
               

                <!-- Text input for other makes -->
                <ng-template #textInputForOther>
                  <!-- Input: Year -->
                  <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                    <mat-label>{{ 'welcome.makeName' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'welcome.enterTheMakeName' | translate }}" formControlName="otherMake" (blur) = "inputBlurEvent('make')" />
                    <mat-error *ngIf="step.controls.otherMake.errors?.required">{{ 'welcome.makeNameIsRequired' | translate }}</mat-error>
                  </mat-form-field>
                  <!-- Input: Year -->
                  <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                    <mat-label>{{ 'welcome.modelYear' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'welcome.enterModelYear' | translate }}" formControlName="model" aspNumberOnly (blur) = "inputBlurEvent('year')" />
                    <mat-error *ngIf="step.controls.year.errors?.required">{{ 'welcome.yearIsRequired' | translate }}</mat-error>
                  </mat-form-field>

                  <!-- Input: Model -->
                  <mat-form-field class="mat-input-container anime-element anime-sec" appearance="outline">
                    <mat-label>{{ 'welcome.model' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'welcome.enterModel' | translate }}" formControlName="year" (blur) = "inputBlurEvent('model')"/>
                    <mat-error *ngIf="step.controls.model.errors?.required">{{ 'welcome.modelIsRequired' | translate }}</mat-error>
                  </mat-form-field>
                </ng-template>
              </div>
              <!-- make-year-model-container -->

              <div id="or" class="anime-element">{{ 'welcome.or' | translate }}</div>

              <!-- Input: VIN -->
              <div class="vin-container">
                <mat-form-field class="mat-input-container anime-element" appearance="outline">
                  <mat-label>{{ 'welcome.enterYourVin' | translate }}</mat-label>
                  <input matInput placeholder="{{ 'welcome.enter17digitVIN' | translate }}" [required]="isVinMandate" formControlName="vin" (blur) = "inputBlurEvent('vin')"/>
                  <mat-error *ngIf="step.controls.vin.errors?.required">{{ 'welcome.vinIsRequired' | translate }}</mat-error>
                  <mat-error *ngIf=" step.controls.vin.errors?.pattern17DigitAndAlphaNumeric">
                    {{step.controls.vin.errors?.pattern17DigitAndAlphaNumeric | translate}}</mat-error>
                  <mat-error *ngIf=" step.controls.vin.errors?.pattern17Digit">
                    {{step.controls.vin.errors?.pattern17Digit | translate}}
                  </mat-error>
                  <mat-error *ngIf=" step.controls.vin.errors?.patternAlphaNumeric">
                    {{step.controls.vin.errors?.patternAlphaNumeric | translate}}</mat-error>
                </mat-form-field>
                <div *ngIf="hasExistingAppt" style="font-size: 14px;color: red;padding: 0 1em;">
                  <sup>{{existingApptText}}</sup>
                </div>
              </div>
              <!-- vin-container -->

              <!-- Input: Mileage -->
              <div class="anime-element">
                <div class="mileage-input-title">{{ 'welcome.enterYourMileage' | translate }}</div>
                <div class="mileage-input-hint">
                  {{ 'welcome.provideAppropriateMileage' | translate }}
                </div>
              </div>
              <mat-form-field class="mat-input-container anime-element" appearance="outline">
                <mat-label>{{ estimatedMiles }}</mat-label>
                <input matInput placeholder="{{ enterMiles }}" formControlName="mileage" aspNumberOnly required (blur) = "inputBlurEvent('mileage')" />
                <mat-error *ngIf="step.controls.mileage.errors?.required">{{ milesIsRequired }}</mat-error>
              </mat-form-field>
            </div>
            <asp-info-button name="{{'welcome.next' | translate}}"  type='secondary' (iClick)="continueToService()" [disabled]="!step.valid">
            </asp-info-button>
          </form>
        </div>
      </ng-container>

      <ng-container #tab2 *ngIf="viewMode == 'tab2'">
        <div class='tab-container'>
          <form [formGroup]="searchForm">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'welcome.phoneEmailOrVIN' | translate }}</mat-label>
              <input matInput required formControlName="searchText" (blur)="searchTextTracking()">
            </mat-form-field>
            <asp-info-button name="{{'welcome.search' | translate}}" type='secondary' (iClick)="onSearchClicked()" [disabled]="!searchForm.valid">
            </asp-info-button>
          </form>
        </div>
      </ng-container>
    </div>
    <div *ngIf="isLoginVisible" class='login-container'>
      <div style="font-size:15px;">
        <span>{{ 'welcome.LogInWithYourMyToyotaOrSmartPathAccount' | translate }}</span>
      </div>
      <div>
        <asp-info-button (click)=" login()" [name]="'welcome.logIn' | translate | uppercase" type='secondary'>
        </asp-info-button>
      </div>
      <div>
        <p class="signUpActions">{{ 'welcome.donTHaveAMyToyotaOrSmartPathAccount' | translate }} <a target="blank" class="link" id="signUpLink"
            href="{{registerUrl}}" (click)="newUser()">{{ 'welcome.createAccount' | translate }}</a></p>
      </div>
    </div>
  </div>
  <div class="col-md-6" style="padding: 0px; overflow: hidden;">
    <img class="welcome-image d-none d-md-block" alt="Welcome"
      src="{{'images/'+brand+'/welcome-'+brand+'.png' | assetUrl}}" />
    <img class="welcome-image d-sm-none" alt="Welcome" style="margin-bottom: -1px;"
      src="{{'images/'+brand+'/welcome-'+brand+'-mobile.png' | assetUrl}}" />
  </div>
</div>
<app-asp-footer *ngIf="!inDealerSite"></app-asp-footer>
